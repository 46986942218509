import React, { useEffect, useState } from "react";
import supabase from "../config/supabaseClient";
import PartnerCard from "./PartnerCard";

const FetchPartners = () => {
  const [fetchError, setFetchError] = useState(null);
  const [partners, setPartners] = useState(null); // Changed 'boards' to 'partners'

  useEffect(() => {
    const fetchPartners = async () => {
      // Changed 'fetchBoards' to 'fetchPartners'
      const { data, error } = await supabase.from("Partners").select();

      if (error) {
        setFetchError("Could not fetch partners");
        setPartners(null); // Changed 'setBoards' to 'setPartners'
        console.log(error);
      }
      if (data) {
        setPartners(data); // Changed 'setBoards' to 'setPartners'
        setFetchError(null);
        console.log("Data was fetched");
      }
    };
    fetchPartners(); // Changed 'fetchBoards' to 'fetchPartners'
  }, []);

  return (
    <div className="p-4">
      {" "}
      {/* Added padding for overall container */}
      {fetchError && <p>{fetchError}</p>}
      {partners && (
        <div className="flex flex-wrap justify-center">
          {" "}
          {/* Added flex container */}
          {partners.map((partner) => (
            <PartnerCard key={partner.id} partner={partner} />
          ))}
        </div>
      )}
      <p>*All data is currently placeholders for development purposes.</p>
    </div>
  );
};

export default FetchPartners;
