import React from "react";
//import SupabaseFetchTest from "../components/SupabaseFetchTest";
import PartnersHeader from "../components/PartnersHeader";
import FetchPartners from "../components/FetchPartners";

const CardPage = () => {
  return (
    <div>
      <PartnersHeader />
      {/* <SupabaseFetchTest /> */}
      <FetchPartners />
    </div>
  );
};

export default CardPage;
