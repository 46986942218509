import React from "react";

const SocialLink = ({ linkIcon: LinkIcon, href }) => {
  return (
    <a
      className="flex items-center justify-center rounded-full h-10 w-10 border-2 border-[#00df9a] hover:bg-[#00df9a] hover:text-white transition-all hover:cursor-pointer"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <LinkIcon />
    </a>
  );
};

export default SocialLink;
