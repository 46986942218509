import React from "react";

const PartnerCard = ({ partner }) => {
  return (
    <div className="border border-white hover:border-[#00df9a] transition-colors duration-300 p-8 w-[500px] m-8 flex flex-col rounded-xl hover:cursor-pointer">
      <div className="flex items-center mb-8">
        <h2 className="text-3xl font-semibold text-white mr-3">
          {partner.partner_company}
        </h2>
        <div className="bg-[#00df9a] rounded-full w-3 h-3"></div>
      </div>

      <div className="flex items-center">
        <span className="text-lg text-gray-400 mr-3">Boards:</span>
        <p className="text-4xl font-semibold text-white">
          {partner.total_boards}
        </p>
      </div>
    </div>
  );
};

export default PartnerCard;
