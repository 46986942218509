import React from "react";
import Button from "../components/Parts/Button";

const PartnersHeader = () => {
  return (
    <section className=" text-center py-16 border-b border-white">
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-white mb-4">
          Elevate Your Advertising Technology
        </h1>
        <p className="text-lg text-gray-400 leading-relaxed mb-8">
          Join the growing list of forward-thinking providers leveraging
          Streetmark to expand their advertising reach.
        </p>
        <div className="flex justify-center">
          <Button title={"Get Started"} to={"/pricing"} />
        </div>
        <div className="w-20 h-1 bg-[#00df9a] rounded-md mx-auto mt-6"></div>
      </div>
    </section>
  );
};

export default PartnersHeader;
